import React from "react";
import { ReactComponent as Logo } from "./img/pluri-black.svg";

function LogoIcon() {
  return (
    <div>
      <Logo width="1.5em" fill="#22A6BF" />
    </div>
  );
}

export default LogoIcon;
