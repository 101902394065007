import React from "react";
import { ReactComponent as LogoText } from "./img/pluri-logo-text.svg";

function LogoTextIcon() {
  return (
    <div>
      <LogoText width="1.5em" height="1em" fill="#22A6BF" />
    </div>
  );
}

export default LogoTextIcon;
