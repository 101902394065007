import React from "react";
import Authentication from "../components/Authentication";
//import WhatIsPluri from "../components/WhatIsPluri";
import Grid from "@material-ui/core/Grid";
import Logo from "../components/icons/LogoIcon";
import LogoText from "../components/icons/LogoTextIcon";

function Landing() {
  return (
    <div className="App">
      <Grid container justify="center">
        <Grid item xs={12}>
          <div className="App-header">
            <Logo />
            <LogoText />
          </div>
        </Grid>
        <Grid item xs={12} justify="center">
          <div>
            <Authentication />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Landing;
