import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Add,
  Edit,
  Save,
  Email,
  Group,
  Stars,
  Assignment,
} from "@material-ui/icons";
import { Auth, Storage } from "aws-amplify";
import { AmplifyS3ImagePicker } from "@aws-amplify/ui-react";
import { DropzoneArea } from "material-ui-dropzone";
import { beforeAfter } from "aws-amplify-react";
import Avatar from "react-avatar-edit";
import userDefaultPic from "./default-avatar.png";
import clsx from "clsx";

const styles = {
  pic: {
    height: "12em",
    width: "12em",
    color: "#fff",
    border: "0",
    marginTop: "1em",
  },
  picDiv: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  img: {
    width: "100%",
    height: "100%",
    backgroundImage: "url(user.png)",
    borderRadius: "40px",
    boxShadow: "-0px -5px 15px #2d109a, 0px 5px 15px #0b0426",
    padding: "0.2em",
  },
  imageUploader: {
    display: "none",
  },
  flexBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  plus: {
    fontSize: "5em",
    color: "#20e9e7",
  },
  info: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    color: "#20e9e7",
    fontSize: "1.2em",
  },
  name: {
    fontSize: "1.3em",
    fontWeight: "600",
    textTransform: "capitalize",
    margin: "0",
    marginTop: "1em",
    height: "1.5em",
    padding: "0",
  },
  testing: {
    border: "3px dashed red",
  },
  button: {
    background: "rgba(0,0,0,0)",
    border: "0px",
  },
  icon: {
    color: "#20e9e7",
  },
  indent: {
    marginLeft: "0.75em",
  },
};

const initialInfo = {
  name: "Name",
  email: "Email",
  groups: ["Add group"],
  assignments: ["Add assignment"],
};

function ProfilePage(props) {
  const { classes } = props;
  const [name, setName] = useState("Add your name");
  const [email, setEmail] = useState("Click to add email");
  const [image, setImage] = useState(userDefaultPic);
  const profilePic = useRef(null);
  const imageUploader = React.useRef(null);
  const [groups, setGoups] = useState([
    {
      org: "as",
      subGroups: [],
    },
  ]);
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    loadAttributes();
  }, []);
  async function updateUser(e) {
    e.preventDefault();
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        name: name,
      });
      console.log("updated");
    } catch (error) {
      console.log("error", error);
    }
  }

  async function loadAttributes() {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const pPic = await Storage.list("profilePicture", { level: "protected" })
        .then((result) => {
          console.log(result[0]);
          if (result.length > 0) {
            const pPic = Storage.get(result[0].key, {
              level: "protected",
            }).then((result) => setImage(result));
          }
        })
        .catch((err) => console.log(err));
      setEmail(user.attributes.email);
      setName(user.attributes.name);
    } catch (err) {
      console.log("error loading attribs", err);
    }
  }

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = profilePic;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
      Storage.put("profilePicture", file, {
        level: "protected",
        contentType: "image/png",
      })
        .then((result) => console.log("this result", result))
        .catch((err) => console.log("this error", err));
    }
  };
  return (
    <div>
      <Grid container className={classes.flexBox}>
        <Grid item md={6} className={classes.picDiv}>
          <input
            className={classes.imageUploader}
            ref={imageUploader}
            id="imageUpload"
            accept="image/*"
            type="file"
            multiple="false"
            onChange={handleImageUpload}
          />
          <div
            className={classes.pic}
            onClick={() => imageUploader.current.click()}
          >
            <img className={classes.img} ref={profilePic} src={image} />
          </div>
        </Grid>
        <Grid item md={6} className={classes.info}>
          <List>
            <ListItem>
              <input
                className={clsx(classes.name)}
                type="text"
                placeholder={name}
                onChange={(e) => setName(e.target.value)}
                id="fname"
                name="fname"
                onBlur={updateUser}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.icon}>
                <Email />
              </ListItemIcon>
              {email}
            </ListItem>
            <br />
            <ListItem>
              <ListItemIcon className={clsx(classes.icon, classes.indent)}>
                <Group />
              </ListItemIcon>
              Organizations: {groups.length}
            </ListItem>
            <ListItem>
              <ListItemIcon className={clsx(classes.icon, classes.indent)}>
                <Group />
              </ListItemIcon>
              Sub-groups: {groups.length}
            </ListItem>
            <ListItem>
              <ListItemIcon className={clsx(classes.icon, classes.indent)}>
                <Stars />
              </ListItemIcon>
              Badges: {groups.length}
            </ListItem>
            <ListItem>
              <ListItemIcon className={clsx(classes.icon, classes.indent)}>
                <Assignment />
              </ListItemIcon>
              Assignments: {assignments.length}
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ProfilePage);
