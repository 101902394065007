/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:55aa9240-422c-4d7c-a288-8c6d49627b6c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Fmejnxeal",
    "aws_user_pools_web_client_id": "jpli73i7dv55buehhf3eve2ig",
    "oauth": {},
    "aws_user_files_s3_bucket": "pluri6e6a415f3ccf452995680503ec0bf3ea230437-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
