import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  Button,
  ButtonGroup,
  Backdrop,
  withStyles,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  AppBar,
  Fab,
  Avatar,
  Fade,
  Divider,
} from "@material-ui/core";
import {
  AccountBox,
  GroupRounded,
  ViewListRounded,
  ExitToApp,
  ListAltRounded,
  Apps,
  Dashboard,
} from "@material-ui/icons";
import { Auth } from "aws-amplify";

const styles = {
  appbar: {
    position: "static",
    boxShadow: "-5px -5px 10px #260e83, 5px 5px 10px #12063d",
    background: "rgba(0,0,0,0)",
    borderRadius: "0 0 20px 20px",
    paddingTop: "0.3em",
    marginBottom: "1em",
  },
  profNav: {
    float: "right",
  },
  groupNav: {
    float: "left",
  },
  appNav: {
    paddingTop: "0",
    paddingBottom: "0.05em",
    //color: "red",
    fontSize: "3.5em",
    width: "1.2em",
    background: "#1c0a60",
    boxShadow:
      "-0px -0px 0px #260e83, 0px 0px 0px #12063d, 0px 10px 10px #12063d",
    borderRadius: "0 0 5px 5px",
  },
  navBtn: {
    background: "rgba(0,0,0,0)",
    border: "0px",
    boxShadow: "0px 0px 0px",
    color: "#20e9e7",
  },
  avatar: {
    background: "rgba(0,0,0,0)",
  },
  backdrop: {
    // background: "rgba(5,21,23,1)",
    // backdropFilter: "blur(15px)",
    // transitionDuration: "2000",
  },
};
function Navbar3(props) {
  const { classes } = props;
  const [name, setName] = useState("Add your name");
  const [email, setEmail] = useState("Click to add email");
  const [groups, setGoups] = useState([
    {
      org: "as",
      subGroups: [],
    },
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState({
    profile: false,
    groups: false,
    projects: false,
    backdrop: true,
  });
  useEffect(() => {
    loadAttributes();
  }, []);
  async function loadAttributes() {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      // console.log(user);
      setEmail(user.attributes.email);
      setName(user.attributes.name);
    } catch (err) {
      console.log("error loading attribs", err);
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpen = (e) => {
    setOpen(() => ({ ...open, [e]: true, backdrop: true }));
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    setOpen(() => ({ ...open, [e]: false, backdrop: false }));
  };
  const handleChange = (e, f) => {
    setAnchorEl(null);
    setOpen(() => ({ ...open, [e]: false, backdrop: false }));
  };
  return (
    <div>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <Grid container xs={12}>
            <Grid item xs={4}>
              <Fab
                className={(classes.profNav, classes.navBtn)}
                onClick={handleClick}
              >
                <Avatar className={classes.avatar} />
              </Fab>
              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                TransitionComponent={Fade}
                // anchorOrigin={{
                //   vertical: "bottom",
                //   horizontal: "center",
                // }}
                transformOrigin={{
                  // vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to="/profile"
                  >
                    <Avatar />
                    &nbsp; {email}
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Dashboard /> &nbsp;<span>Dashboard</span>
                </MenuItem>
                <MenuItem onClick={() => Auth.signOut()}>
                  <ExitToApp /> &nbsp;<span>Sign Out</span>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={4}>
              <Fab className={classes.navBtn}>
                <Apps className={classes.appNav} />
              </Fab>
            </Grid>
            <Grid item xs={4}>
              <Fab className={(classes.groupNav, classes.navBtn)}>
                <GroupRounded />
              </Fab>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(Navbar3);
