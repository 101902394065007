import React, { useState, useEffect } from "react";
import "../App.css";
import { Button, ButtonGroup, withStyles, TextField } from "@material-ui/core";
import { Auth, Hub } from "aws-amplify";
import { useSnackbar } from "notistack";
const initialFormState = {
  username: "",
  password: "",
  email: "",
  authCode: "",
  formType: "signIn",
};

const styles = {
  root: {
    display: "flex",
  },
  header: {
    padding: "0.25em 2em",
    marginTop: "1em",
    color: "rgba(255, 255, 255, 0.4)",
  },
  highlight: {
    textAlign: "left",
    color: "#E96020",
    fontSize: "1.3em",
    padding: "0.2em",
    border: "0",
    fontWeight: "600",

    borderBottom: "2px solid rgba(8, 209, 207, 0.5)",
    borderRadius: "0",
  },
  offlight: {
    textAlign: "left",
    padding: "0.2em",
    fontSize: "1.2em",
    fontWeight: "lighter",
    border: "0",
    color: "rgba(255,255,255,0.5)",
  },
  auth: {
    borderRadius: "40px",
    background: "#1C0A60",
    boxShadow: "0px 20px 60px #17084f, -0px -20px 120px #210c71",
    height: "20em",
    border: "0",
    width: "20em",
    marginLeft: "calc(50vw - 10em)",
    borderTop: "1px solid rgba(8, 209, 207, 0.1)",
  },
  submit: {
    margin: "2em",
    padding: "0.5em 2em",
    borderRadius: "0px",
    background: "#1C0A60",
    boxShadow: "0px 4px 10px #17084f,-0px -4px 10px #210c71",
    fontSize: "1.2em",
    color: "#20e9e7",
    border: "0",

    "&:active, &:focus": {
      margin: "2em",
      padding: "0.5em 2em",
      borderRadius: "0px",
      background: "#1C0A60",
      boxShadow: "0px 4px 10px #17084f inset,0px -4px 10px #210c71 inset",
      border: "0",
      color: "#999",
    },
  },
};
function Authentication(props) {
  const [formState, updateFormState] = useState(initialFormState);
  const { classes } = props; // props from withStyles
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      //  updateUser(user);
      updateFormState(() => ({ ...formState, formType: "signedIn" }));
    } catch (error) {
      console.log(error);
    }
  }
  function onChange(e) {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  function swapForm(e) {
    updateFormState(() => ({ ...formState, formType: e }));
  }
  const { formType } = formState;

  function handleKeyPress(key, type) {
    console.log(key.keyCode, type);
    if (type === "signIn") {
      if (key.keyCode == 13) {
        console.log("keyyyed!");
      }
    }
  }
  async function signUp(e) {
    let { username, password, email } = formState;
    e.preventDefault();
    try {
      await Auth.signUp({ username, password, attributes: { email } });
      updateFormState(() => ({ ...formState, formType: "confirmSignUp" }));
    } catch (error) {
      enqueueSnackbar("Error: Please review your sign up info", {
        variant: "error",
        preventDuplicate: true,
        dense: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  }
  async function confirmSignUp() {
    const { username, authCode } = formState;
    console.log("username is", username);
    try {
      await Auth.confirmSignUp(username, authCode);
      updateFormState(() => ({ ...formState, formType: "signIn" }));
    } catch (error) {
      enqueueSnackbar("Incorrect code", {
        variant: "error",
        preventDuplicate: true,
        dense: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  }
  async function signIn(e) {
    const { username, password } = formState;
    e.preventDefault();
    try {
      await Auth.signIn(username, password);
      updateFormState(() => ({ ...formState, formType: "signedIn" }));
    } catch (error) {
      enqueueSnackbar("Sign in error: Please review your login info", {
        variant: "error",
        preventDuplicate: true,
        dense: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      //console.log("error signing in", error);
    }
  }

  return (
    <div className={classes.auth}>
      {formType === "signUp" && (
        <div>
          <div className={classes.header}>
            <div>
              <button
                className={classes.offlight}
                onClick={() => swapForm("signIn")}
              >
                SIGN IN
              </button>{" "}
              <span>or</span>{" "}
              <button
                className={classes.highlight}
                onClick={() => swapForm("signUp")}
              >
                SIGN UP
              </button>
            </div>
          </div>

          <br />
          <form>
            {" "}
            <input name="username" onChange={onChange} placeholder="email" />
            <input
              name="password"
              type="password"
              onChange={onChange}
              placeholder="password"
            />
            <Button className={classes.submit} onClick={signUp}>
              SIGN UP
            </Button>
          </form>
        </div>
      )}

      {formType === "confirmSignUp" && (
        <div>
          <input
            name="authCode"
            onChange={onChange}
            placeholder="Comfirmation code"
            className={classes.textField}
          />

          <button onClick={confirmSignUp}>Confirm SIGN UP</button>
        </div>
      )}
      {formType === "signIn" && (
        <div>
          <div className={classes.header}>
            <div>
              <button
                className={classes.highlight}
                onClick={() => swapForm("signIn")}
              >
                SIGN IN
              </button>{" "}
              <span>or</span>{" "}
              <button
                className={classes.offlight}
                onClick={() => swapForm("signUp")}
              >
                SIGN UP
              </button>
            </div>
          </div>

          <br />
          <form onSubmit={signIn}>
            <input name="username" onChange={onChange} placeholder="email" />
            <br />
            <input
              name="password"
              type="password"
              onChange={onChange}
              placeholder="password"
            />
            <br />
            <Button type="submit" className={classes.submit}>
              SIGN IN
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(Authentication);
