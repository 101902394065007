import React, { useState, useEffect } from "react";
import "./App.css";
import Landing from "./pages/Landing";
import { Auth, Hub } from "aws-amplify";
import Workspace from "./pages/Workspace.js";
import GroupsPage from "./pages/Groups";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ProfilePage from "./pages/ProfilePage";
import Navbar from "./components/Navbar3";
import AdminPage from "./pages/Admin";
import { SnackbarProvider } from "notistack";

function App() {
  const [loginState, updateLoginState] = useState(false);
  const [user, updateUser] = useState(null);

  useEffect(() => {
    checkLogin();
    setAuthListener();
  }, [loginState]);

  async function setAuthListener() {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          updateLoginState(true);
          console.log(data.payload.event);
          break;
        case "signOut":
          updateLoginState(false);
          console.log(data.payload.event);
          break;
        default:
          break;
      }
    });
  }

  async function checkLogin() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      updateUser(user);
      updateLoginState(true);
      console.log("login success");
    } catch (err) {
      updateLoginState(false);
      console.log("error in login: ", err);
    }
  }
  return (
    <SnackbarProvider>
      {loginState === false && (
        <>
          <Landing />
        </>
      )}
      {loginState === true && (
        <>
          <Router>
            <Navbar />
            <div className="app-container">
              <Route exact path="/" component={Workspace} />
              <Route path="/profile" component={ProfilePage} />
            </div>
          </Router>
        </>
      )}
    </SnackbarProvider>
  );
}

export default App;
